import { Suspense, memo, useContext } from 'react'
import { Switch, Route } from 'react-router-dom'

import { LinearProgress } from '@material-ui/core'

import { routes } from 'app/routes'

import PageNotFound from 'pages/PageNotFound'
import { LoginPage } from 'pages/LoginPage'

import { AuthContext } from './AuthProvider'

export const AppRouter = memo(() => {
    const { isLoading, showLoginPage } = useContext(AuthContext)

    if (isLoading) return null

    if (showLoginPage) {
        return <LoginPage />
    }

    return (
        <Suspense fallback={<LinearProgress />}>
            <Switch>
                {routes.map(route => (
                    <Route key={route.path} exact={true} path={route.path}>
                        {route.component}
                    </Route>
                ))}
                <Route component={PageNotFound} />
            </Switch>
        </Suspense>
    )
})
