import firebase from 'firebase/app'

export const sendWatchedNotification = (
    orgId: string,
    uid: string,
    playbookId: string
) => {
    return firebase
        .database()
        .ref(`/playbookViews/${orgId}/${uid}/${playbookId}`)
        .set({
            timestamp: firebase.database.ServerValue.TIMESTAMP
        })
        .catch(console.error)
}

export const getPlaybookReactions = (
    uid: string,
    playbookId: string,
    callback: (_arg: any) => void
) => {
    return firebase
        .database()
        .ref(`playbookReactions/${uid}/${playbookId}/reaction`)
        .on('value', snapshot => {
            const data: { reaction: number } = snapshot.val() || {}
            callback(data.reaction)
        })
}

export const ERRORS_DICTIONARY = {
    forbidden: 'Forbidden',
    forLoggedUsersOnly: 'forLoggedUsersOnly'
} as const
