import { useCallback, useContext, useEffect, useState } from 'react'

import {
    Box,
    makeStyles,
    Typography,
    Divider,
    IconButton
} from '@material-ui/core'

import HappyIcon from '@material-ui/icons/SentimentSatisfiedSharp'
import SadIcon from '@material-ui/icons/SentimentDissatisfiedSharp'

import { NotHelpfulDialog } from './NotHelpfulDialog'
import { notHelpfulReaction, helpfulReaction } from './VideoReactions'

import { useBoolean } from 'hooks'
import { AuthContext } from 'components'
import { logToAnalytics, setUserReaction, getPlaybookReactions } from 'modules'

import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    divider: {
        margin: theme.spacing(2, 0)
    },
    activeIcon: {
        color: '#ffca28'
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    disabled: {
        color: '#ffca28 !important'
    }
}))

type Props = {
    playbookId: string
    creatorUid: string
}

export const MobileVideoReactions = ({ playbookId, creatorUid }: Props) => {
    const classes = useStyles()
    const dialog = useBoolean()

    const { user } = useContext(AuthContext)

    const [reaction, setReaction] = useState(0)

    const getReaction = useCallback(() => {
        getPlaybookReactions(user?.uid || '', playbookId, setReaction)
    }, [user?.uid, playbookId])

    useEffect(() => {
        getReaction()
    }, [getReaction])

    const isActive = (value: number) => reaction === value

    const sendHelpfulReaction = () => {
        setUserReaction({
            uid: user?.uid,
            playbookId,
            reaction: helpfulReaction
        }).then(() => {
            logToAnalytics('video_reaction_sent', {
                playbookId,
                userUid: user?.uid,
                reaction: 'happy'
            })
        })
    }

    // hide if user is owner of this playbook
    if (user?.uid === creatorUid) return null

    return (
        <Box>
            <Divider />
            <Box display="flex" justifyContent="center">
                <Box display="flex" justifyContent="center" width="50%">
                    <Box className={classes.center}>
                        <Box mb={-1}>
                            <IconButton
                                classes={{
                                    disabled: classes.disabled
                                }}
                                onClick={sendHelpfulReaction}
                                disabled={isActive(helpfulReaction)}
                                className={clsx({
                                    [classes.activeIcon]:
                                        isActive(helpfulReaction)
                                })}
                            >
                                <HappyIcon />
                            </IconButton>
                        </Box>
                        <Typography variant="body2">Helpful</Typography>
                        <Box pb={1} />
                    </Box>
                </Box>
                <Box>
                    <Divider orientation="vertical" />
                </Box>
                <Box display="flex" justifyContent="center" width="50%">
                    <Box className={classes.center}>
                        <Box display="flex" justifyContent="center" mb={-1}>
                            <IconButton
                                classes={{
                                    disabled: classes.disabled
                                }}
                                onClick={dialog.setTrue}
                                disabled={isActive(notHelpfulReaction)}
                                className={clsx({
                                    [classes.activeIcon]:
                                        isActive(notHelpfulReaction)
                                })}
                            >
                                <SadIcon />
                            </IconButton>
                        </Box>

                        <Typography variant="body2">Not Helpful</Typography>
                        <Box pb={1} />
                    </Box>
                </Box>
            </Box>
            <Divider />
            <NotHelpfulDialog
                isOpen={dialog.isTrue}
                onClose={dialog.setFalse}
                playbookId={playbookId}
            />
        </Box>
    )
}
