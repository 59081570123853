import firebase from 'firebase/app'
import { formatTime } from 'modules'

const transcriptRef = (id: string) =>
    firebase.firestore().doc(`transcripts/${id}`)

export const updateTranscript = (playbookId: string, segments: Array<any>) => {
    // remove new lines from text
    const segmentsArr = segments?.map(segment => {
        return {
            ...segment,
            text: segment.text.replace('\n', ' ')
        }
    })

    return transcriptRef(playbookId)
        .update({ segments: segmentsArr, transcriptEdited: Date.now() })
        .catch(console.error)
}

export const fetchTranscript = async (url: string, callback: any) => {
    let response = await fetch(url)
    let data = await response.text()
    callback(JSON.parse(data))
}

export const getTranscript = (playbookId: string) => {
    return transcriptRef(playbookId)
        .get()
        .then(transcript => transcript.data())
        .catch(console.error)
}

export const getTranscriptSegments = async (url: string, callback: any) => {
    await fetch(url, { method: 'GET' })
        .then(res => res.text())
        .then(text => {
            const lines: any = text.split('\n')

            if (lines.length > 0 && lines[0] !== '0') return

            const segmentsArr = []
            for (let i = 0; i < lines.length; i += 2) {
                segmentsArr.push({
                    time: formatTime(lines[i]),
                    text: lines[i + 1]
                })
            }
            callback(segmentsArr)
        })
        .catch(console.error)
}
