import { useLayoutEffect, useMemo, useState } from 'react'

export const useElementSize = (
    element: Element | undefined,
    extraDependency?: any
) => {
    const [width, setWidth] = useState<number>(0)
    const [height, setHeight] = useState<number>(0)

    useLayoutEffect(() => {
        setWidth((element?.clientWidth || 0) + 1)
        setHeight((element?.clientHeight || 0) + 1)
    }, [element, element?.clientHeight, element?.clientWidth, extraDependency])

    return useMemo(() => ({ width, height }), [height, width])
}
