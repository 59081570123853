import {
    Box,
    Container,
    makeStyles,
    Button,
    Typography,
    Link
} from '@material-ui/core'

import { firebaseConfig } from 'env'

const useStyles = makeStyles(theme => ({
    container: {
        color: '#212121',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
        height: 'calc(100vh - 48px)'
    },
    content: {
        width: '400px',
        maxWidth: '90%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        background: 'white',
        borderRadius: '4px',
        padding: theme.spacing(3, 2),
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)'
    },
    link: {
        border: 'none',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none'
        }
    }
}))

type Props = {
    playbookId?: string
    isPlaylist?: boolean
}

const PageNotFound = ({ playbookId, isPlaylist }: Props) => {
    const classes = useStyles()

    // playlist or playbook path with playbookId
    const path = isPlaylist ? '/playlist' : '/playbooks'
    const finalPath = playbookId ? path + `/${playbookId}` : ''

    return (
        <Container className={classes.container}>
            <Box className={classes.content}>
                <Box mb={2}>
                    <Typography variant="h2">
                        This video's privacy settings require you to sign into
                        your Guidde account.
                    </Typography>
                </Box>

                <Link
                    className={classes.link}
                    href={`https://${firebaseConfig.authDomain}${finalPath}`}
                    rel="noreferrer noopener"
                    target="_blank"
                >
                    <Button>View it on Guidde</Button>
                </Link>
            </Box>
        </Container>
    )
}

export default PageNotFound
