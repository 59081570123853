import { memo, useContext, useEffect } from 'react'

import { Box, Button, makeStyles, Typography } from '@material-ui/core'

import { useBoolean } from 'hooks'
import clsx from 'clsx'

import { AuthContext, SpacedGroup } from 'components'

import { delay, setUserReaction, logToAnalytics } from 'modules'
import { notHelpfulReaction, negativeAnswersList } from './VideoReactions'

const useStyles = makeStyles(theme => ({
    icon: {
        width: '25px',
        height: '25px',
        cursor: 'pointer',
        color: '#fff',
        transition: 'transform 0.2s ease 0s',
        transform: 'translateY(0)'
    },
    iconContainer: {
        '&:hover svg': {
            color: '#ffca28',
            transform: 'translateY(-5px)'
        }
    },
    activeIcon: {
        color: '#ffca28'
    },
    negativeAnswers: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingBottom: '5%',
        left: 0,
        right: 0,
        bottom: 0,
        height: '50%',
        backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), #000)'
    },
    negativeAnswer: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        maxWidth: '150px',
        padding: '6px 12px',
        height: '60px',
        backdropFilter: 'blur(10px)',
        '&:hover': {
            backgroundColor: '#fff',
            color: theme.palette.text.primary
        }
    },
    title: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: '#fff',
        textAlign: 'center',
        width: '100%',
        position: 'relative'
    },
    subTitle: {
        fontSize: '12px',
        color: '#fff',
        textAlign: 'center'
    },
    ratherNot: {
        fontSize: '12px',
        color: 'inherit',
        fontWeight: 'bold',
        position: 'absolute',
        paddingRight: theme.spacing(3),
        right: 0,
        top: 0,
        cursor: 'pointer'
    },
    finishStep: {
        paddingBottom: '10%'
    }
}))

export const VideoReactionsAnswers = memo(
    ({ showReactions, playbookId, showFinishStep = false }: any) => {
        const classes = useStyles()

        const { user } = useContext(AuthContext)

        const finishStep = useBoolean(showFinishStep)

        const handleClick = (e: any, result = 0) => {
            e.persist()
            setUserReaction({
                uid: user?.uid,
                playbookId,
                reaction: notHelpfulReaction,
                result
            }).then(() => {
                finishStep.setTrue()
                logToAnalytics('video_reaction_sent', {
                    playbookId,
                    userUid: user?.uid,
                    reaction: 'sad',
                    result
                })
            })
        }

        const finishStepIsActive = finishStep.isTrue
        const setFinishStep = finishStep.setFalse
        const setShowReactions = showReactions.setFalse

        useEffect(() => {
            if (finishStepIsActive) {
                delay(2500).then(() => {
                    setShowReactions()
                    setFinishStep()
                })
            }
        }, [finishStepIsActive, setFinishStep, setShowReactions])

        if (finishStep.isTrue) {
            return (
                <Box
                    className={clsx(
                        classes.negativeAnswers,
                        classes.finishStep
                    )}
                >
                    <Typography className={classes.title}>
                        Thank you for your feedback!
                    </Typography>
                    <Typography className={classes.subTitle}>
                        We are working hard to avoid mismatches
                    </Typography>
                </Box>
            )
        }

        if (showReactions.isFalse) return null

        return (
            <Box className={classes.negativeAnswers}>
                <Box m={2} width="100%">
                    <Typography className={classes.title}>
                        Ouch! Would you mind tell us why?
                        <Typography
                            component="span"
                            className={classes.ratherNot}
                            onClick={handleClick}
                        >
                            I rather not
                        </Typography>
                    </Typography>
                </Box>
                <SpacedGroup>
                    {negativeAnswersList.map((text, i) => (
                        <Button
                            variant="contained"
                            key={i}
                            className={classes.negativeAnswer}
                            onClick={e => handleClick(e, i + 1)}
                        >
                            {text}
                        </Button>
                    ))}
                </SpacedGroup>
            </Box>
        )
    }
)
