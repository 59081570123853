import { forwardRef, useEffect, useState } from 'react'
import { generatePath } from 'react-router-dom'

import clsx from 'clsx'

import {
    makeStyles,
    Box,
    Tooltip,
    Link,
    Typography,
    useTheme,
    useMediaQuery
} from '@material-ui/core'

import { PlayControls } from '@guidde-co/shared.video-player.play-controls'
import { TimelineWithSections } from '@guidde-co/shared.video-player.timeline-with-sections'
import { CustomControls } from '@guidde-co/shared.video-player.controls.custom-controls'

import { ReactComponent as GuiideLogo } from 'assets/icons/guidde_white.svg'

import { VideoReactionsAnswers } from 'components'

import { useBoolean, getVideoPlayerId, UseBooleanType, useQuery } from 'hooks'

import { ShareButton } from './ShareButton'

import { PlaybookType } from 'app/types'
import { paths } from 'app/paths'

import { firebaseConfig } from 'env'

const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        background: 'black'
    },
    link: {
        color: 'white',
        zIndex: 1,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none'
        },
        cursor: 'pointer',
        position: 'absolute',
        left: '15px',
        bottom: '65px',
        borderRadius: '4px',
        overflow: 'hidden'
    },
    video: {
        height: '100%',
        maxHeight: '95vh',
        display: 'block'
    },
    btn: {
        height: 'fit-content',
        '& svg': {
            margin: '0 3px'
        }
    },
    title: {
        color: '#FFFFFF',
        textShadow: '0px 2px 2px rgba(0, 0, 0, 0.8)',
        fontSize: 20,
        width: 'calc(100% - 120px)',
        display: '-webkit-box',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 500
    },
    titleContainer: {
        zIndex: 2,
        background:
            'linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%)',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        top: 0,
        left: 0,
        right: 0,
        height: 80
    },
    badge: {
        left: '-4px',
        top: '-4px',
        fontWeight: 600,
        fontSize: '10px'
    },
    speedBtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgb(38 50 56 / 50%)',
        minWidth: '40px',
        minHeight: '40px',
        borderRadius: '50%',
        padding: 0,
        color: '#fff',
        '&:hover': {
            backgroundColor: 'rgb(38 50 56 / 80%)'
        }
    },
    btnDisabled: {
        backgroundColor: '#212121',
        color: 'grey'
    },
    hoverControls: {
        opacity: 0,
        position: 'absolute',
        top: '70px',
        right: '23px',
        '& > *': {
            marginBottom: theme.spacing(1),
            '&:nth-child(2)': {
                transitionDelay: '0.1s'
            },
            '&:nth-child(3)': {
                transitionDelay: '0.2s'
            },
            '&:nth-child(4)': {
                transitionDelay: '0.3s'
            }
        }
    },
    animated: {
        '& > *': {
            opacity: 0,
            transform: 'translateX(30px)',
            transition: 'opacity, transform 0.1s linear'
        }
    },
    visible: {
        opacity: 1,
        zIndex: 1,
        '& > *': {
            opacity: 1,
            transform: 'translateX(0)'
        }
    },
    playInExtBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    playControls: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        zIndex: 1
    },
    tooltip: {
        marginTop: theme.spacing(2.5)
    }
}))

type StatusType = 'loading' | 'playing' | 'stopped'

const getStatus = (e: any) => (e.target.paused ? 'stopped' : 'playing')

type Props = {
    tooltip?: string
    hoverControls?: JSX.Element
    showReactions?: UseBooleanType
    showFinishStep?: boolean
    playbook: PlaybookType
    hideReactions?: boolean
    onPlay?: () => void
    onPause?: () => void
    onEnded?: () => void
    onTimeUpdate?: (_e: any) => void
    height?: string
    autoPlay?: boolean
    controls?: boolean
}

export const ControlledVideoPlayer = forwardRef<HTMLVideoElement | null, Props>(
    (
        {
            hoverControls,
            showReactions,
            showFinishStep,
            playbook,
            hideReactions,
            tooltip,
            onEnded,
            ...videoProps
        }: any,
        ref: any
    ) => {
        const {
            id: playbookId,
            url: playbookUrl,
            subtitlesUrl,
            isPlaylist
        } = playbook
        const classes = useStyles()
        const hoverState = useBoolean()
        const hidePlayControls = useBoolean()
        const isShowSubtitlesButton = useBoolean(true)

        const theme = useTheme()
        const mdDown = useMediaQuery(theme.breakpoints.down('sm'))

        const [status, setStatus] = useState<StatusType>('loading')

        const $publicFF = useQuery(
            `/c/v1/feature-flags-public?orgId=${playbook.uploadedByOrgId}`,
            {
                method: 'GET'
            }
        )

        const captions = useBoolean(Boolean($publicFF.data?.showCaptions))

        const setHideControls = hidePlayControls.set
        useEffect(() => {
            if (status === 'loading' || status === 'playing') {
                setHideControls(true)
            } else {
                setHideControls(false)
            }
        }, [status, setHideControls])

        const playbookPath = generatePath(paths.sharePlaybook, { playbookId })

        const playlistPath = generatePath(paths.sharePlaylist, {
            playlistId: playbookId
        })

        const copyLinkPath = isPlaylist ? playlistPath : playbookPath

        const playerID = getVideoPlayerId()

        const showPlayButton = status !== 'loading' && hidePlayControls.isFalse

        if ($publicFF.isValidating) return null

        return (
            <Box
                className={classes.container}
                onMouseEnter={hoverState.setTrue}
                onMouseLeave={hoverState.setFalse}
            >
                {(status !== 'playing' || hoverState.isTrue) && (
                    <Box className={classes.titleContainer} p={3}>
                        <Typography className={classes.title}>
                            {tooltip}
                        </Typography>
                        <Box zIndex={3}>
                            <ShareButton playbook={playbook} videoRef={ref} />
                        </Box>
                    </Box>
                )}
                <Box
                    position="relative"
                    style={{ cursor: 'pointer' }}
                    // 100% - height of video controls
                    height="calc(100% - 55px)"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    zIndex={1}
                    onClick={() => {
                        if (
                            !ref?.current ||
                            status === 'loading' ||
                            videoProps.controls
                        )
                            return

                        if (status === 'playing') {
                            ref.current.pause()
                        } else {
                            ref.current.play()
                        }
                    }}
                >
                    <video
                        crossOrigin="anonymous"
                        className={classes.video}
                        ref={ref}
                        width="100%"
                        height="auto"
                        controls={false}
                        autoPlay
                        onContextMenu={e => e.preventDefault()}
                        controlsList="nodownload"
                        {...videoProps}
                        src={playbookUrl}
                        id={playerID}
                        onPlay={() => {
                            videoProps?.onPlay()
                            setStatus('playing')
                        }}
                        onPause={() => {
                            videoProps?.onPause()
                            setStatus('stopped')
                        }}
                        onSeeking={() => setStatus('loading')}
                        onLoadedData={(e: any) => setStatus(getStatus(e))}
                        onSeeked={(e: any) => setStatus(getStatus(e))}
                        onEnded={() => {
                            setStatus('stopped')
                            onEnded?.()
                        }}
                        onPlaying={hidePlayControls.setTrue}
                        onTimeUpdate={(e: any) => {
                            const time = e?.target?.currentTime

                            if (time === undefined || !e?.target?.duration)
                                return

                            videoProps?.onTimeUpdate(time)
                        }}
                    >
                        {subtitlesUrl &&
                            isShowSubtitlesButton.isTrue &&
                            captions.isTrue && (
                                <track
                                    label="English"
                                    kind="subtitles"
                                    srcLang="en"
                                    src={subtitlesUrl}
                                    default
                                />
                            )}
                    </video>
                </Box>
                <Box position="absolute" bottom={-10} left={0} right={0}>
                    <TimelineWithSections
                        sections={playbook.slicingSuggestion || []}
                        videoElement={ref.current as HTMLVideoElement}
                    />
                    <CustomControls
                        showLogo={hoverState.isTrue && status === 'playing'}
                        isFocused={hoverState.isTrue}
                        videoElement={ref.current}
                        onCaptionsClick={captions.setTrue}
                        captionsInitialState={captions.isTrue}
                        chapters={playbook.slicingSuggestion}
                    />
                </Box>
                {showPlayButton && (
                    <Tooltip
                        title={tooltip || ''}
                        classes={{
                            popper: classes.tooltip
                        }}
                    >
                        <Box className={classes.playControls}>
                            <PlayControls
                                onClick={() => {
                                    if (status === 'playing') {
                                        ref.current.pause()
                                        setStatus('stopped')
                                        return
                                    }
                                    ref.current.play()
                                    setStatus('playing')
                                }}
                                visible={hoverState.isTrue}
                                status={status}
                            />
                        </Box>
                    </Tooltip>
                )}
                {(hoverState.isTrue || showPlayButton) &&
                    !$publicFF.data.hideWatchOnGuiddeInEmbed && (
                        <Link
                            className={classes.link}
                            href={`https://${firebaseConfig.authDomain}/${copyLinkPath}`}
                            rel="noreferrer noopener"
                            target="_parent"
                        >
                            <Box
                                bgcolor="#090C10"
                                py="10px"
                                px="30px"
                                style={{
                                    opacity: 0.8
                                }}
                                display="flex"
                                alignItems="center"
                            >
                                <Box mr={2} style={{ fontSize: '12px' }}>
                                    Watch on
                                </Box>
                                <GuiideLogo style={{ height: '25px' }} />
                            </Box>
                        </Link>
                    )}

                {!hideReactions && (
                    <VideoReactionsAnswers
                        showReactions={showReactions}
                        showFinishStep={showFinishStep}
                        playbookId={playbookId}
                    />
                )}
                {!mdDown && (
                    <Box
                        display="flex"
                        flexDirection="column"
                        className={clsx(
                            classes.hoverControls,
                            classes.animated,
                            hoverState.isTrue && classes.visible
                        )}
                    >
                        {hoverControls}
                    </Box>
                )}
            </Box>
        )
    }
)
