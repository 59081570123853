import { useState, useEffect, useMemo } from 'react'

export const useRealtimeVideoTime = () => {
    const [activeTime, setActiveTime] = useState(0)

    const playerID = getVideoPlayerId()

    useEffect(() => {
        const interval = setInterval(function () {
            const time = (<HTMLVideoElement>document.getElementById(playerID))
                ?.currentTime
            setActiveTime(time)
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    }, [playerID])

    return useMemo(() => activeTime, [activeTime])
}

export const getVideoPlayerId = () =>
    process.env.NODE_ENV === 'development'
        ? 'GUIDDE-PLAYER_DEV'
        : 'GUIDDE-PLAYER'
