import firebase from 'firebase'

import { SignContent } from '@guidde-co/shared.sign-content'

import { Box } from '@material-ui/core'

import { firebaseConfig } from 'env'

type SignInProvider = 'google.com' | 'microsoft.com'

const guiddeDomain = firebaseConfig.authDomain

export const LoginPage = () => {
    const continueWithPopup = (source: SignInProvider) => {
        const provider = new firebase.auth.OAuthProvider(source)

        firebase.auth().signInWithPopup(provider).catch(console.error)
    }

    const getAuthUrl = async (domain: string) => {
        const response = await fetch(
            `https://${guiddeDomain}/os/v1/auth?domain=${domain}&state=${window.location.href}`
        )
        return response.json()
    }

    return (
        <Box
            maxHeight="100vh"
            height="100%"
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <SignContent
                title="Log in"
                analyticKey="sign-in"
                label="LOG IN"
                initialTab={1}
                mobileMaxWidth={390}
                googleLabel="Continue with Google"
                microsoftLabel="Continue with Microsoft"
                hideTabs={true}
                emailValue=""
                decreaseMargins={2}
                isMobileView={true}
                isLoading={false}
                onGoogleClick={() => continueWithPopup('google.com')}
                onMicrosoftClick={() => continueWithPopup('microsoft.com')}
                onSubmit={({ domain, setSubmitting, setSSOError }) => {
                    getAuthUrl(domain).then(res => {
                        if (res?.authUrl) {
                            document.location.href = res?.authUrl
                        } else {
                            setSSOError()
                            setSubmitting(false)
                        }
                    })
                }}
            />
        </Box>
    )
}
