import { createContext, useEffect, useState } from 'react'
import firebase from 'firebase'
import { useBoolean } from 'hooks'

const initialValue = {
    user: null
}

type AuthType = {
    user: null | { uid: string; isAnonymous: boolean }
}

export const AuthContext = createContext<
    AuthType & {
        setLoginPage: (_val: boolean) => void
        showLoginPage: boolean
        isLoading: boolean
    }
>({
    user: null,
    setLoginPage: () => {},
    isLoading: false,
    showLoginPage: false
})

type Props = {
    children: JSX.Element
}

export const AuthProvider = ({ children }: Props) => {
    const [auth, setAuth] = useState<AuthType>(initialValue)

    const loading = useBoolean(true)
    const showLoginPage = useBoolean()

    const cancelLoading = loading.setFalse
    const hideLoginPage = showLoginPage.setFalse

    useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
            if (!user) {
                firebase.auth().signInAnonymously().finally(cancelLoading)
                hideLoginPage()
                return
            }

            user.getIdTokenResult()
                .then(auth => {
                    setAuth({
                        user: {
                            uid: String(auth.claims.user_id),
                            isAnonymous: auth.signInProvider === 'anonymous'
                        }
                    })
                })
                .finally(cancelLoading)

            hideLoginPage()
        })
    }, [hideLoginPage, cancelLoading])

    return (
        <AuthContext.Provider
            value={{
                ...auth,
                isLoading: loading.isTrue,
                showLoginPage: showLoginPage.isTrue,
                setLoginPage: showLoginPage.set
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
