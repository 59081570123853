import { useMemo } from 'react'
import useSWR from 'swr'

import { request } from 'modules'

export const useQuery = (
    url: string,
    { method, body }: any = {},
    params?: any
) => {
    const { data, error, revalidate, mutate, isValidating } = useSWR(
        [url, method, JSON.stringify(body)],
        (url, method, body) => request(url, method, body),
        {
            ...params,
            shouldRetryOnError: false,
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false
        }
    )

    return useMemo(
        () => ({ data, error, revalidate, mutate, isValidating }),
        [data, error, isValidating, mutate, revalidate]
    )
}
