import { useContext, useCallback, useState, ReactNode } from 'react'
import { generatePath } from 'react-router-dom'
import { createPortal } from 'react-dom'

import { AuthContext } from 'components'

import { ShareOnlyDialog } from '@guidde-co/shared.share-only-dialog'

import { Snackbar, IconButton } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

import CloseIcon from '@material-ui/icons/Close'

import { PlaybookType, VideoRefType } from 'app/types'
import { paths } from 'app/paths'

import { useBoolean } from 'hooks'
import { logToAnalytics, playbookToAnalyticsProps, request } from 'modules'

type Props = {
    isOpen: boolean
    onClose: () => void
    videoRef: VideoRefType
    playbook: PlaybookType
}

export const validateEmail = (email: string) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

const messageTypes = {
    SUCCESS: 'success',
    ERROR: 'error'
} as const

const initialMessageState = {
    type: messageTypes.SUCCESS,
    text: ''
} as const

type PortalProps = {
    children: ReactNode
}

const ReactPortal = ({ children }: PortalProps) => {
    const container = document.getElementById('portal')

    if (container) {
        return createPortal(children, container)
    }

    return null
}

export const ShareDialog = ({ videoRef, playbook, onClose, isOpen }: Props) => {
    const { isPlaylist, isPublic, id: playbookId } = playbook

    const [message, setMessage] = useState<{
        type: 'success' | 'error'
        text: string
    }>(initialMessageState)

    const { user } = useContext(AuthContext)

    const uid = user?.uid || ''

    const loading = useBoolean()
    const setLoadingFalse = loading.setFalse
    const setLoadingTrue = loading.setTrue

    const share = useCallback(
        (body: { playbookId: string; emails: Array<string> }) => {
            setLoadingTrue()
            return request('/b/v1/share-to-email', 'POST', JSON.stringify(body))
                ?.then(() => {
                    logToAnalytics('share_playbook', {
                        sharedByUid: uid,
                        sentTo: body.emails,
                        playbookIds: [body.playbookId]
                    })
                    onClose()
                    showSuccessNotification('Video shared successfully')
                })
                .catch(e => {
                    console.error(e)
                    onClose()
                    showErrorNotification('Something went wrong')
                })
                .finally(setLoadingFalse)
        },
        [setLoadingTrue, setLoadingFalse, onClose, uid]
    )

    const playbookPath = generatePath(
        !isPublic ? paths.playbookDetails : paths.sharePlaybook,
        { playbookId }
    )

    const playlistPath = generatePath(
        !isPublic ? paths.playlistDetails : paths.sharePlaylist,
        { playlistId: playbookId }
    )

    const copyLinkPath = isPlaylist ? playlistPath : playbookPath

    const handleClose = () => setMessage(initialMessageState)

    const showSuccessNotification = (text: string) => {
        setMessage({
            type: messageTypes.SUCCESS,
            text
        })
    }
    const showErrorNotification = (text: string) => {
        setMessage({
            type: messageTypes.ERROR,
            text
        })
    }

    return (
        <>
            <ShareOnlyDialog
                isOpen={isOpen}
                uid={uid}
                hideCopyLink={true}
                onClose={onClose}
                onShare={({ emails }) => {
                    const includeInvalidEmail = emails.some(
                        email => !validateEmail(email)
                    )

                    if (includeInvalidEmail) {
                        showErrorNotification('Some email is invalid')
                        return
                    }

                    share({
                        playbookId: playbook.id,
                        emails
                    })
                }}
                title={`Share your ${
                    playbook.isPlaylist ? 'playlist' : 'video'
                }`}
                steps={playbook.steps}
                playbook={playbook}
                isLoading={loading.isTrue}
                currentTime={videoRef?.current?.currentTime || 0}
                copyLinkPath={copyLinkPath}
                onCopySharedLink={() => {
                    showSuccessNotification('Link copied to the clipboard')
                    logToAnalytics(
                        'copyLink',
                        playbookToAnalyticsProps(playbook)
                    )
                }}
                onCopyGifLink={() => {
                    showSuccessNotification(
                        'Linked GIF was copied to the clipboard'
                    )

                    const context =
                        playbook.mode === 'quickGuidde'
                            ? 'copy-linked-gif-qg'
                            : 'copy-linked-gif'

                    logToAnalytics('copyHtmlClicked', {
                        context,
                        ...playbookToAnalyticsProps(playbook)
                    })
                }}
            />
            <ReactPortal>
                <Snackbar
                    open={Boolean(message.text)}
                    autoHideDuration={6000}
                    style={{ zIndex: 1301, position: 'fixed' }}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={handleClose}
                        style={{ zIndex: 2000 }}
                        severity={message.type}
                        action={
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={handleClose}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        }
                    >
                        {message.text}
                    </MuiAlert>
                </Snackbar>
            </ReactPortal>
        </>
    )
}
