import { useContext, useMemo } from 'react'

import firebase from 'firebase'

import { AuthContext } from 'components'

export const usePlaybookAuth = () => {
    const { setLoginPage } = useContext(AuthContext)

    return useMemo(
        () => ({
            onSuccess: () => setLoginPage(false),
            onError: (err: Error) => {
                const isAnonymous = firebase.auth().currentUser?.isAnonymous
                // show login page ONLY if user is an anonymous.
                // if user IS NOT anonymous and received forbidden again
                // that means that video is restricted and we should show login.
                if (err.message === 'Forbidden') {
                    setLoginPage(Boolean(isAnonymous))
                }
            }
        }),
        [setLoginPage]
    )
}
