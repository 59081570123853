import { ChangeEvent, useState, useContext } from 'react'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    LinearProgress,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import { notHelpfulReaction, negativeAnswersList } from './VideoReactions'

import { logToAnalytics, setUserReaction } from 'modules'
import { AuthContext } from 'components'
import { useBoolean } from 'hooks'

const useStyles = makeStyles(theme => ({
    title: {
        textAlign: 'center',
        fontWeight: 600
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        padding: theme.spacing(0.5),
        margin: theme.spacing(0.5)
    },
    actions: {
        padding: theme.spacing(3),
        justifyContent: 'center'
    },
    label: {
        fontSize: '13px'
    }
}))

type Props = {
    isOpen: boolean
    onClose: () => void
    loading?: boolean
    playbookId: string
}

export const NotHelpfulDialog = ({ isOpen, onClose, playbookId }: Props) => {
    const classes = useStyles()

    const loading = useBoolean()
    const { user } = useContext(AuthContext)

    const [value, setValue] = useState<string | number>('')

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(+(event.target as HTMLInputElement).value)
    }

    const sendReaction = () => {
        const result = null

        loading.setTrue()

        setUserReaction({
            uid: user?.uid,
            playbookId,
            reaction: notHelpfulReaction,
            result
        })
            .then(() => {
                onClose()
                logToAnalytics('video_reaction_sent', {
                    playbookId,
                    userUid: user?.uid,
                    reaction: 'sad',
                    result
                })
                setValue('')
            })
            .finally(loading.setFalse)
    }

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth>
            {loading.isTrue && <LinearProgress />}
            <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
            >
                <CloseIcon />
            </IconButton>

            <DialogTitle>
                <Typography className={classes.title}>
                    Would you mind tell us why?
                </Typography>
            </DialogTitle>
            <DialogContent>
                <FormControl component="fieldset">
                    <RadioGroup
                        name="gender1"
                        value={value}
                        onChange={handleChange}
                    >
                        {negativeAnswersList.map((text, i) => (
                            <FormControlLabel
                                key={i}
                                value={i + 1}
                                control={<Radio />}
                                label={
                                    <Typography
                                        variant="subtitle2"
                                        className={classes.label}
                                    >
                                        {text}
                                    </Typography>
                                }
                            />
                        ))}

                        <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label={
                                <Typography
                                    variant="subtitle2"
                                    className={classes.label}
                                >
                                    Rather not
                                </Typography>
                            }
                        />
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button
                    size="medium"
                    onClick={sendReaction}
                    disabled={loading.isTrue || value === ''}
                >
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    )
}
