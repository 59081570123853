import { memo } from 'react'

import { Button } from '@material-ui/core'
import PublicIcon from '@material-ui/icons/PublicOutlined'

import { PlaybookType, VideoRefType } from 'app/types'

import { useBoolean } from 'hooks'

import { ShareDialog } from './ShareDialog'

type Props = {
    videoRef: VideoRefType
    playbook: PlaybookType
}

export const ShareButton = memo((props: Props) => {
    const shareDialog = useBoolean()

    return (
        <>
            <Button
                onClick={shareDialog.setTrue}
                startIcon={<PublicIcon fontWeight="small" />}
                style={{ fontWeight: 500 }}
                size="small"
            >
                SHARE
            </Button>
            <ShareDialog
                isOpen={shareDialog.isTrue}
                onClose={shareDialog.setFalse}
                {...props}
            />
        </>
    )
})
