import {
    event as logFullStoryEvent,
    identify as identifyFullStoryUser,
    getCurrentSessionURL
} from '@fullstory/browser'
import { setUser as setSentryUser } from '@sentry/browser'

import { isLocalhost, request } from './utils'

const noop = () => {}

const checkIsLocal = (func: (_args?: any) => void) =>
    isLocalhost ? noop : func

// Identifies user in FullStory + Sentry
const registerUserInAnalytics = checkIsLocal(
    ({ uid, env, browser, browserVersion, os }) => {
        const appInfo = {
            source: 'embed',
            environment: env,
            'anonymous-playbook': true,
            browser,
            browserVersion,
            os
        }

        const userInfo = {
            'user-id': uid
        }

        // Fill in properties which we use every time when call backend to track any analytic event
        globalProperties = { ...appInfo }
        userProperties = { ...userInfo }

        // SENTRY
        setSentryUser({
            id: uid,
            ...appInfo,
            ...userInfo
        })

        // FULLSTORY
        identifyFullStoryUser(uid, { ...appInfo, ...userInfo })
    }
)

// We use these properties in use-video-portal to enrich videoPlay event
export let userProperties = {}
export let globalProperties = {}

const trackEvent = (event: string, props = {}) => {
    return request(
        '/tk/v1/track',
        'POST',
        JSON.stringify({
            userProperties,
            globalProperties,
            eventData: {
                event,
                ...props
            }
        })
    )
}

// Also logs an event to FullStory
const logToAnalytics: (_event: string, _eventData: any) => void = checkIsLocal(
    (event: string, eventData: any = {}) => {
        const sessionURL = getCurrentSessionURL(true)

        const eventProps = {
            ...eventData,
            sessionURL,
            url: window.location.href
        }
        trackEvent(event, eventProps)?.catch(e =>
            console.error(e, '< TRACK ERROR')
        )
        logFullStoryEvent(event, eventProps)
    }
)

// UTILS
const playbookToAnalyticsProps = (playbook: any) => ({
    'pb-app': playbook.applications?.[0]?.applicationName || null,
    'pb-appId': playbook.applications?.[0]?.applicationId || null,
    'pb-apps': playbook.applications || null,
    'pb-tags': playbook.tags,
    'pb-id': playbook.id,
    'pb-title': playbook.title,
    'pb-creator-org-id': playbook.uploadedByOrgId,
    'pb-creator-name': playbook.username,
    'organization-id': playbook.uploadedByOrgId // Note: we need it for embed playbook only
    // as anonymous user doesn't have orgId, but we need orgId for analytics
})

export { logToAnalytics, registerUserInAnalytics, playbookToAnalyticsProps }
