import { useCallback, useEffect, useMemo, useState } from 'react'

import { Chip, makeStyles } from '@material-ui/core'
import firebase from 'firebase/app'

const useApplications = (applications: Array<string>) => {
    const [apps, setApps] = useState<any>([])

    const getApplications = useCallback(() => {
        Promise.all(
            applications.map(app => {
                return firebase
                    .firestore()
                    .collection(`applications`)
                    .doc(app)
                    .get()
                    .then((snap: { data: () => any }) => snap.data())
            })
        ).then(setApps)
    }, [applications])

    useEffect(() => {
        getApplications()
    }, [getApplications])

    return {
        apps
    }
}

const useStyles = makeStyles(theme => ({
    root: {
        '& .Mui-disabled': {
            opacity: 1
        },
        marginLeft: '-3px'
    },

    logo: {
        height: '15px',
        width: 'auto'
    },
    chipRoot: {
        padding: theme.spacing(0.5),
        margin: theme.spacing(0.5, 0.5, 0, 0),
        fontWeight: 500,
        fontSize: '12px',
        background: '#eeeeee'
    }
}))

type Props = {
    values: Array<{ applicationId: string }>
}

export const MultipleApplications = ({ values: applications = [] }: Props) => {
    if (!applications.length) return null

    const classes = useStyles()

    const appIds = useMemo(
        () => applications.map(app => app.applicationId),
        [applications]
    )

    const { apps: fullAppsInfo } = useApplications(appIds)

    return (
        <>
            {applications.map((app: any, idx: number) => {
                const appLogo = fullAppsInfo.find(
                    (appInfo: { id: string }) =>
                        appInfo.id === app.applicationId
                )?.logo

                return (
                    <Chip
                        icon={<img src={appLogo} className={classes.logo} />}
                        key={idx}
                        label={app.applicationName}
                        classes={{
                            root: classes.chipRoot
                        }}
                    />
                )
            })}
        </>
    )
}
