import firebase from 'firebase/app'

const reactionRef = ({ uid, playbookId }: any) =>
    firebase.database().ref(`/playbookReactions/${uid}/${playbookId}/reaction`)

export const setUserReaction = ({
    uid,
    playbookId,
    reaction,
    result = null
}: any) =>
    reactionRef({ playbookId, uid }).set({
        reaction,
        result,
        lastEdited: firebase.database.ServerValue.TIMESTAMP
    })
