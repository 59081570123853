import { useMemo } from 'react'

import { Box } from '@material-ui/core'

import { VideoOverlay } from '@guidde-co/shared.video-player.video-overlay'

import { PlaybookType, VideoRefType } from 'app/types'

import { useBoolean, UseBooleanType } from 'hooks'

import { ShareDialog } from './ShareDialog'

const EMBED_PATTERN = /^embed\./

const applicationHost = window.location.host.replace(EMBED_PATTERN, '')

export type VideoOverlayDataType = {
    brandKit: {
        logo: string
        color: string
    }
}

type Props = {
    showVideoOverlay: UseBooleanType
    overlayData?: VideoOverlayDataType
    videoRef: VideoRefType
    playbook: PlaybookType
    watchLabel?: string
    onWatchClick?: () => void
}

export const VideoOverlayWrapper = ({
    showVideoOverlay,
    videoRef,
    overlayData,
    playbook,
    onWatchClick,
    watchLabel = 'Watch Again'
}: Props) => {
    const { id, uploadedByOrgId } = playbook

    const isEnterprisePlan = Boolean(overlayData)

    const shareDialog = useBoolean()

    const utmParams = useMemo(() => {
        const query = new URLSearchParams()

        query.append('utm_source', 'guidde-app')
        query.append('utm_medium', 'embed-end-screen')
        query.append('utm_campaign', 'get+started')
        query.append('utm_term', id)
        query.append('utm_content', uploadedByOrgId)

        return query.toString()
    }, [id, uploadedByOrgId])

    const startPlaying = () => {
        if (!videoRef.current) return

        showVideoOverlay.setFalse()
        videoRef.current.currentTime = 0
        videoRef.current.play()
    }

    if (showVideoOverlay.isFalse) return null

    return (
        <Box position="absolute" top={0} left={0} right={0} bottom={0}>
            <VideoOverlay
                isVisible={showVideoOverlay.isTrue}
                onWatch={() => {
                    startPlaying()
                    onWatchClick?.()
                }}
                buttonLabel={isEnterprisePlan ? 'Powered by Guidde' : undefined}
                watchLabel={watchLabel}
                onShare={shareDialog.setTrue}
                icon={
                    isEnterprisePlan ? (
                        <img
                            src={overlayData?.brandKit.logo}
                            style={{
                                height: 40,
                                width: 'auto',
                                objectFit: 'cover'
                            }}
                        />
                    ) : undefined
                }
                buttonColor={overlayData?.brandKit.color}
                title={
                    isEnterprisePlan
                        ? playbook.title
                        : 'How-To Videos Made Simple'
                }
                subtitle={
                    isEnterprisePlan
                        ? ''
                        : 'Instantly capture a video like this one for any workflow or software.'
                }
                showGuiddeForFreeLink={`https://${applicationHost}/signup?${utmParams}`}
            />

            <ShareDialog
                isOpen={shareDialog.isTrue}
                playbook={playbook}
                videoRef={videoRef}
                onClose={shareDialog.setFalse}
            />
        </Box>
    )
}
